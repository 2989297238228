import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"
import { HeroTitle, Section, BreadCrumb, MainPanel, LeftPanel, RightPanel } from "../components/Section"
import {
  SidebarSticky,
  SidebarForm,
  SidebarFormHeader,
  SidebarFormTitle,
  SidebarFormBody,
  SidebarCard,
  SidebarCardHeader,
  SidebarCardTitle,
  SidebarCardBody
} from "../components/Sidebar"
import {
  MediaList,
  Media,
  MediaThumb,
  MediaBody,
  ListBadge,
  ListItemBadge,
  Badge
} from "../components/ListGroup"
import QuickContactForm from "../components/QuickContactForm"
import ArticleList from "../components/ArticleList"
import Pagination from '../components/Pagination';
import Img from "gatsby-image"
import { extractStaticUrlStr } from '../utils/string'

const NoContent = styled.h3`
	text-align: center;
`

const BlogPage = ({ data, location, formName }) => {
  const {
    allContentfulArticle: { edges: totalArticles },
    allContentfulArticleCategory: { edges: categories }
  } = data
  const [isCurrent, setIsCurrent] = useState(location?.state?.category || "All Blog");
  const [articlesData, setArticlesData] = useState([]);
  const [showArticles, setShowArticles] = useState([]);
  const pageSize = 6;
  const recentPosts = totalArticles.slice(0, 3);

  useEffect(() => {
    if (isCurrent === "All Blog") {
      setArticlesData(totalArticles);
    } else {
      let articles = [];
      totalArticles.forEach(item => {
        if (item.node.category.name === isCurrent) {
          articles.push(item)
        }
      });
      setArticlesData(articles);
    }
  }, [isCurrent])

  useEffect(() => {
    if (articlesData.length <= pageSize) {
      setShowArticles(articlesData)
    }
  }, [articlesData])

  const articleCount = (categoryName) => {
    let count = 0;
    totalArticles.forEach(item => {
      if (item.node.category.name === categoryName) {
        count++;
      }
    });
    return count;
  }

  const refreshPage = (pageNo) => {
    const startIndex = (pageNo - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, articlesData.length);
    const pageData = articlesData.slice(startIndex, endIndex);
    setShowArticles([...pageData]);
    document.getElementById("list-content").scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Layout location={location}>
      <SEO title="Blog - About" description="Blog" />
      <Section pt="250px" pb="60px" mtp="170px">
        <div className="container">
          <BreadCrumb top="-40px" mTop="-40px"><Link to="/">Home</Link> / <span>Blogs</span></BreadCrumb>
          <HeroTitle>Blogs</HeroTitle>
          <p>Check out our most recent blog posts for valuable information about steel buildings and unique storage solutions. With everything from organization tips to information about standards and regulations across the country our blog can be a great tool to help keep you informed and entertained.</p>
        </div>
      </Section>
      <Section pt="81px" pb="103px" bg="#F4FBFF" id="list-content">
        <div className="container">
          <MainPanel>
            <LeftPanel>
              {articlesData.length > 0 ? (
                <Fragment>
                  <ArticleList data={showArticles} />
                  {articlesData.length > pageSize && (
                    <Pagination
                      pages={Math.ceil(articlesData.length / pageSize)}
                      onChange={refreshPage}
                      refresh={isCurrent}
                      contentRef={document.getElementById("list-content")}
                    />
                  )}
                </Fragment>
              ) : (<NoContent>No Related Blog Content</NoContent>)}
            </LeftPanel>
            <RightPanel>
              <SidebarSticky className="sidebar isSticky">
                <SidebarCard>
                  <SidebarCardHeader><SidebarCardTitle>Recent Posts</SidebarCardTitle></SidebarCardHeader>
                  <SidebarCardBody>
                    <MediaList className="media-list">
                      {recentPosts.map((post, i) => (
                        <Media className="media" key={i}>
                          <MediaThumb className="media-thumb">
                            <Img
                              fluid={post.node.tumbnail.fluid}
                              alt={`thumbnail-${post.node.title}`}
                            />
                          </MediaThumb>
                          <MediaBody className="media-body">
                            <Link to={`/blog/${extractStaticUrlStr(post.node.title)}/`}><p>{post.node.title}</p></Link>
                            <small>{post.node.createdAt}</small>
                          </MediaBody>
                        </Media>
                      ))}
                    </MediaList>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarCard>
                  <SidebarCardHeader><SidebarCardTitle>Blog Categories</SidebarCardTitle></SidebarCardHeader>
                  <SidebarCardBody>
                    <ListBadge className="list-badge">
                      <ListItemBadge className="list-item">
                        <Badge
                          bg={isCurrent === "All Blog" ? "#ED602B" : "white"}
                          color={isCurrent === "All Blog" ? "white" : "#140F0F"}
                          onClick={() => setIsCurrent("All Blog")}>
                          <span className='tag'>All Blog</span>
                          <span className='count'>{totalArticles.length}</span>
                        </Badge>
                      </ListItemBadge>
                      {categories.map((category, i) => (
                        <ListItemBadge className="list-item" key={i}>
                          <Badge
                            bg={isCurrent === category.node.name ? "#ED602B" : "white"}
                            color={isCurrent === category.node.name ? "white" : "#140F0F"}
                            onClick={() => setIsCurrent(category.node.name)}
                          >
                            <span className='tag'>{category.node.name}</span>
                            <span className='count'>{articleCount(category.node.name)}</span>
                          </Badge>
                        </ListItemBadge>
                      ))}
                    </ListBadge>
                  </SidebarCardBody>
                </SidebarCard>
                <SidebarForm>
                  <SidebarFormHeader><SidebarFormTitle>GET IN TOUCH</SidebarFormTitle></SidebarFormHeader>
                  <SidebarFormBody>
                    <QuickContactForm location={location} formName={formName ? formName : "Quick Blog Form"} />
                  </SidebarFormBody>
                </SidebarForm>
              </SidebarSticky>
            </RightPanel>
          </MainPanel>
        </div>
      </Section>
      <CallToAction />
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
	query BlogPageQuery {
	  allContentfulArticle (sort: {fields: createdAt, order: DESC}) {
	    edges {
	      node {
	        title
	        tumbnail {
	          fluid(maxWidth: 1500) {
	            aspectRatio
	            base64
	            sizes
	            src
	            srcSet
	            srcSetWebp
	            srcWebp
	          }
	        }
	        description {
	          description
	        }
	        category {
	        	name
	        }
	        createdAt(formatString: "D MMM YYYY")
	      }
	    }
	  }
	  allContentfulArticleCategory {
	    edges {
	      node {
	        name
	      }
	    }
	  }
	}
`